import React, {useState, useEffect} from "react";
import "./forgotPassword.scss";
import { Link } from "react-router-dom";
import { Button, Col, Row, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { showLoader, hideLoader} from "../../index";
import { Formik } from "formik";
import Timer from "../../inputs/Timer";
import QueryString from "query-string";
import {FORGOT_PASSWORD_OTP, FORGOT_PASSWORD} from "../../../action/authAction";
import {connect} from "react-redux";
import {ToastsStore} from "react-toasts";

const ForgotPasswordOTP = (props) => {
  const { auth, forgotPasswordOtp , resendOtp } = props;
  const params = QueryString.parse(props.location.search);
  const [reload,setReload] =useState(false);
  const [isOtpExpired, setOtpExpired] =useState(false);
  const expireOTP = () => {
    setOtpExpired(!isOtpExpired);
  }

  useEffect(() => {
    if(!auth.isLoading) {
      hideLoader();
    }
  }, [auth]);

  return (
    <div className="forgot-password-otp-page page-area container-fluid">
      <div className="forgot-main-block">
        <Formik
          initialValues={{ otpNumber: "" }}
          setSubmitting
          onSubmit={(values, { setSubmitting }) => {
              if(values.otpNumber.length !== 4){
                  ToastsStore.error("Please enter complete OTP");
                  return;
              }
            showLoader();
            forgotPasswordOtp({...values, email:params.email});
            setSubmitting(false);
          }}
        >
          {(formProps) => {
            const {values, handleSubmit, setFieldValue} = formProps;

            return (
              <Form onSubmit={handleSubmit}>
                <Row className="forgot-main__inner">
                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <h4 className="mb-4 font-weight-normal">
                        Forgot Password
                      </h4>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <h4 className="mb-3">OTP sent to your email</h4>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner rqt-block">
                      <p className="rqt-content">
                        Request confirmed. Please copy and paste the 4-digit OTP
                      </p>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="otp-input-block">
                      <OtpInput
                        numInputs={4}
                        name="otpNumber"
                        value={values.otpNumber}
                        onChange={(val) => {
                          setFieldValue("otpNumber", val);
                        }}
                        containerStyle="otp-container"
                        inputStyle="otp-input"
                        focusStyle="otp-input-focus"
                        isInputNum="true"
                      />
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <Button variant="danger" className="otp-submit-btn" type="submit">
                        SUBMIT
                      </Button>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="v__inner my-3">
                      <div className="otp-expire-content">
                        <Timer reload={reload} expireOTP={expireOTP} />
                      </div>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      {isOtpExpired ?
                        <Button className="resend-otp-btn" onClick={() => {resendOtp({email:params.email}); setReload(!reload); expireOTP();}}>Resend OTP</Button>
                        : null
                      }
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>

      <footer>
        <div className="page-footer">
          <p className="m-0 footer-content">
            Already a member?
            <Link to="/login"> Login here</Link>
          </p>
        </div>
      </footer>
    </div>
  );
};
function mapStateToProps({auth}) {
    return {auth}
}

const mapDispatchToProps = dispatch => {
    return {
        forgotPasswordOtp: (data) => dispatch({ type: FORGOT_PASSWORD_OTP, payload: data }),
        resendOtp: (data) => dispatch({ type: FORGOT_PASSWORD, payload: data }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordOTP) ;

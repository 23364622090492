import React, {useEffect} from 'react';
import {connect} from 'react-redux';
//import { SignupForm } from 'react-asap-forms';
import { SignupForm } from "../../core_components/react-asap-forms";
import { showLoader, hideLoader, history, Yup } from '../index';
import {REGISTER} from "../../action/authAction";

const Signup = (props) => {
    const {auth, signup} = props;

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
        .required("*Full name is required"),
        password: Yup.string()
        .max(20, "*Password can't be longer than 20 characters")
        .required("*Password is required")
        .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        "*Password must be a minimum of 8 alphanumeric characters, with at least 1 number, 1 character, 1 capital letter"
        ),
        confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required("*Confirm Password is required"),
        email: Yup.string()
        .email("*Please enter a valid email address")
        .min(7, "*Email must have at least 7 characters")
        .max(50, "*Email must be less than 50 characters")
        .required("*Email is required"),
    });

    const initialValues =  {
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
    }

    useEffect(() => {
        if(!auth.isLoading) {
            hideLoader();
        }
    }, [auth]);

    const formSubmit = (val, setSubmitting) => {
        const nameArr = val.fullName.split(' ');
        const params = {firstName: nameArr[0], lastName: nameArr[1] ? nameArr[1] : '', email: val.email, password: val.password};
        showLoader();
        setSubmitting(false);
        signup(params);
    };

    const redirectToLogin = () =>{
        history.push('/login');
    }

    var data = {
        // heading: "Register",
        signupFormSubmit: formSubmit,
        validationSchema,
        initialValues,
        redirectToLogin
    }

    return (
        <>
        <div>
       
        <div className ="logo-div" ></div>
            <SignupForm {...data} />
        <div className ="end-div" > &nbsp;</div>
         </div>
        </>
       
    );
}

function mapStateToProps({auth}) {
    return {auth}
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (data) => dispatch({ type: REGISTER, payload: data }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

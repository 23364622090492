import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import rootSaga from '../../sagas';
import reducers from "../../reducers";

let composeWithDevTools;
if (process.env.NODE_ENV === 'development') {
  composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;
}

let reduxStore;
const initialState = {};

const sagaMiddleware = createSagaMiddleware();

const configureStore = (init = initialState) => {
  return createStore(
    reducers,
    // init,
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(applyMiddleware(promiseMiddleware,thunk, sagaMiddleware))
      : applyMiddleware(promiseMiddleware,thunk, sagaMiddleware)
  );
};

reduxStore = configureStore();
sagaMiddleware.run(rootSaga);

export const store = reduxStore;

const Store = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
);
export default Store;

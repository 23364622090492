import React, {useEffect} from "react";
import {connect} from "react-redux";
import "./resetPassword.scss";
import {Col, Row,Form} from "react-bootstrap";
import Button from "../../inputs/Button";
import {FormInput} from "../../inputs/FormInput";
import {FORGOT_PASSWORD_UPDATE} from "../../../action/authAction";
import {showLoader, hideLoader, Yup} from '../../index';
import {Formik} from "formik";
import QueryString from "query-string";

const ResetPasswordUpdate = (props) => {
    const {auth, resetPassword} = props;
    const params = QueryString.parse(props.location.search);

    const validationSchema = Yup.object().shape({
        pass: Yup.string()
            .max(20, "*Password can't be longer than 20 characters")
            .required("*Password is required")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                "*Password must be a minimum of 8 alphanumeric characters, with at least 1 number, 1 character, 1 capital letter"
            ),
        pass2: Yup.string()
            .oneOf([Yup.ref('pass'), null], 'Passwords must match')
            .required("*Confirm Password is required"),
    });

    useEffect(() => {
        if (!auth.isLoading) {
            hideLoader();
        }
    }, [auth]);
    return (
        <div className="reset-password-email-page page-area container-fluid">

            <div className="page-main-block">
                <div className="reset-password__content">
                    <h3>Update Password</h3>
                </div>
                <Formik
                    initialValues={{pass: '',pass2: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log("values: ", values);
                        showLoader();
                        resetPassword({...values, email:params.email,otpNumber:params.otpNumber});
                        setSubmitting(false);
                    }}
                >
                    {(formProps) => {
                        const {values, errors, touched, handleSubmit, isSubmitting, setFieldValue} = formProps;
                        // console.log("errors")
                        // console.log(errors)
                        // console.log("touched")
                        // console.log(touched)
                        return (
                            <Form onSubmit={handleSubmit}>
                            <Row className="page-main__inner">
                                <Col lg={8} sm={12} className="reset-password__desp">
                                    <p>
                                        Enter Your New Password
                                    </p>
                                </Col>

                                <Col lg={8} sm={12}>
                                    <FormInput label="New Password"
                                               error={errors.pass || touched.pass ? errors.pass : null}
                                               name="pass"
                                               type='password'
                                               value={values.pass}
                                               onChange={(val) => {
                                                   setFieldValue("pass", val.target.value);
                                               }}/>
                                </Col>
                                <Col lg={8} sm={12}>
                                    <FormInput label="Confirm Password"
                                               error={errors.pass2 || touched.pass2 ? errors.pass2 : null}
                                               name="pass2"
                                               type='password'
                                               value={values.pass2}
                                               onChange={(val) => {
                                                   setFieldValue("pass2", val.target.value);
                                               }}/>
                                </Col>
                                <Col lg={8} sm={12}>
                                    <Button type="submit" label="Reset Password"/>
                                </Col>
                            </Row>
                            </Form>
                        )
                    }}

                </Formik>
            </div>
        </div>
    );
}

function mapStateToProps(
    {
        auth
    }
) {
    return {auth};
}

const mapDispatchToProps = (dispatch) => {
        return {
            resetPassword: (data) =>
                dispatch({type: FORGOT_PASSWORD_UPDATE, payload: data}),
        };
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordUpdate);

/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import ShowPassword, { HidePassword } from './show_hide_password';
import styles from './styles.module.css'

export const SignupForm = ({ logo = '', heading = 'Registration', initialValues, validationSchema, signupFormSubmit, redirectToLogin, forotPassword }) => {
    const [passwordType, setPasswordType] = useState('password');
    const passwordEye = (<ShowPassword clickEye={e=> setPasswordType('password')}/>);
    const passwordEyeSlash = (<HidePassword clickEyeSlash={e=> setPasswordType('text')} />);

    const [confirmPasswordType, setConfirmPasswordType] = useState('password');
    const confirmPasswordEye = (<ShowPassword clickEye={e=> setConfirmPasswordType('password')}/>);
    const confirmPasswordEyeSlash = (<HidePassword clickEyeSlash={e=> setConfirmPasswordType('text')} />);
    const fieldNames = Object.keys(initialValues);

    return (
        <Container>
            <h1 className="text-center">{heading}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm})=> {
                    console.log("onSubmit hit");
                    signupFormSubmit(values, setSubmitting, resetForm);
                    // setSubmitting(true);
                }}
                
            >
                {({ errors, touched, values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field name={fieldNames[0]} type="text" placeholder="Full Name"  className={`${errors.fullName && touched.fullName ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                        {errors.fullName && touched.fullName ? <div className={`${styles.red} mb-1`} >{errors.fullName}</div> : null}
                        <Field name={fieldNames[1]} type="email" placeholder="Email" className={`${errors.email && touched.email ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                        {errors.email && touched.email ? (
                            <div className={`${styles.red} mb-1`} >{errors.email}</div>
                        ) : null}
                        <div className={styles.show_password}>
                            <Field name={fieldNames[2]} type={passwordType} placeholder="Password" className={`${errors.password && touched.password ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                            <span>{passwordType === 'text'? passwordEye : passwordEyeSlash}</span>
                        </div>
                        {errors.password && touched.password ? (
                            <div className={`${styles.red} mb-1`} >{errors.password}</div>
                        ) : null}
                        <div className={styles.show_password}>
                            <Field name={fieldNames[3]} type={confirmPasswordType} placeholder="Confirm Password" className={`${errors.confirmPassword && touched.confirmPassword ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                            <span>{confirmPasswordType === 'text'? confirmPasswordEye : confirmPasswordEyeSlash}</span>
                        </div>
                        
                        {errors.confirmPassword && touched.confirmPassword ? (
                            <div className={`${styles.red} mb-1`} >{errors.confirmPassword}</div>
                        ) : null}
                        <Button style={{backgroundColor: "#d53a3a"}} type="submit" disabled={isSubmitting} block>
                            Register
                        </Button>
                        <div className="text-center">
                            <span>
                                <span className={`${styles.v_align_middle}`}>Already a member yet? </span><Button variant="link" className={`${styles.white_link}`} onClick={redirectToLogin}>Login here</Button>
                            </span>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}
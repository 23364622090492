import React,{useEffect,useState} from 'react';
import { Route } from "react-router-dom";
import cookie from 'react-cookies';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {createPromiseAction} from "@adobe/redux-saga-promise";
import {FETCH_PROFILE, FETCH_PROFILE_SUCCESS} from "../../action/profile";


const PrivateRoute = ({component: Component, roles, getProfile, setUser, user, ...props}) => {
  const history = useHistory();
  const token = cookie.load('token');
  const [isAllowed, setIsAllowed] = useState(false);

  const cleanCookie = () => {
    const cookies2 = cookie.loadAll();
    Object.keys(cookies2).map(item => {
      cookie.remove(item, {path:"/"});
    });
  }

  useEffect(()=>{
    if(!roles && token){
      getProfile().then((user) => {
        const user2 = user.data.user;
        setUser(user2);
        setIsAllowed(true);
      }).catch((err) => {
        cleanCookie();
      })
    } else if(roles && !token) {
      history.push("/login");
    } else if(roles && token) {
      if(!user && !token && roles.length > 0) {
        history.push("/login");
      } else {
        getProfile().then((user) => {
          const user2 = user.data.user;
          if(roles.includes(user2.role)) {
            setUser(user2);
            setIsAllowed(true);
          } else {
            if(token) {
              setIsAllowed(false);
              history.push("/");
            } else {
              setIsAllowed(false);
              history.push("/login");
            }
          }
        }).catch((err) => {
          cleanCookie();
          history.push("/login");
        })
      }
    } else {
      setIsAllowed(true);
    }
  },[]);

  return isAllowed ? <Route {...props} component={Component} /> : null;
};

function mapStateToProps({profile}) {
  return {profile};
}

const mapDispatchToProps = dispatch => {
  const fetchPostAction = createPromiseAction(FETCH_PROFILE);
  return {
    getProfile: (data) => dispatch(fetchPostAction(data)),
    setUser:(data) => dispatch({ type: FETCH_PROFILE_SUCCESS, payload: data}),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(PrivateRoute);

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-asap-forms/dist/index.css";
import './App.css';
import "./assets/commonStyles.scss";
import { Router, Route, Switch } from 'react-router-dom';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import PrivateRoute from "./components/privateRoute/PrivateRoute";

//library.add(faTrash, faEdit, faTimes);
import {
  history,
  Home,
  LoginComponent,
  SignupComponent,
  AccountVerification,
  ForgotPassword,
  ForgotPasswordOTP,
  ResetPasswordEmail, ResetPasswordSent, ResetPasswordUpdate,
  DeshboardComponent,
  DeshboardSignin,DeshboardHome,Sidebar,
  DeshboardData,UploadData,ScrapData,Data
} from "./components/index";

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
           <Route path={['/', '/login']} exact component={LoginComponent} />
          <Route path={'/signup'} exact component={SignupComponent} />
          <Route path={'/account_verification'} exact component={AccountVerification} />
          <Route path={'/forgotPassword'} exact component={ForgotPassword} />
          <Route path={'/forgotPasswordOTP'} exact component={ForgotPasswordOTP} />
          <Route path={'/resetPasswordEmail'} exact component={ResetPasswordEmail} />
          <Route path={'/resetPasswordSent'} exact component={ResetPasswordSent} />
          <Route path={'/resetPasswordUpdate'} exact component={ResetPasswordUpdate} />
           <PrivateRoute path={'/home'} exact component={Home} roles={["subscriber"]} />
          <Route path={'/DashboardLogin'} exact  component={DeshboardComponent}/>
          <Route path={'/DashboardSignin'} exact  component={DeshboardSignin}/>
          <Route path={'/DashboardHome'} exact  component={DeshboardHome}/>
          <Route path={'/Sidebar'} exact  component={Sidebar}/>
          <Route path={'/DataListing'} exact  component={DeshboardData}/>
          <Route path={'/UploadData'} exact  component={UploadData}/>
          <Route path={'/Data'} exact  component={ScrapData}/>
          <Route path={'/WeeklyData'} exact  component={Data}/>

        </Switch>
      </Router>
      <ToastsContainer store={ToastsStore}  className="h70"/>
    </div>
  );
}

export default App;

import {
  FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_OTP, FORGOT_PASSWORD_OTP_FAIL,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_UPDATE, FORGOT_PASSWORD_UPDATE_FAIL,
  FORGOT_PASSWORD_UPDATE_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER, REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESEND_VERIFY_EMAIL, RESEND_VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL, VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_SUCCESS,
} from "../action/authAction";
import cookie from 'react-cookies';
import history from '../public/history';
import {ToastsStore} from "../components/index";

const initialState = {
  isLoading: false,
  loginData: {},
  registerData: {},
  verifyEmailData: {},
  forgotPasswordData: {},
};

const authReducer = (state=initialState, {type, payload}) => {
  switch (type) {

    case LOGIN:
    case REGISTER:
    case FORGOT_PASSWORD:
    case FORGOT_PASSWORD_OTP:
    case FORGOT_PASSWORD_UPDATE:
    case VERIFY_EMAIL:
    case RESEND_VERIFY_EMAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      ToastsStore.success("Login successfully.");
      console.log("LOGIN_SUCCESS:", payload);
      const token = payload.tokens.access.token;
      cookie.save('token', token, { path: '/' });
      return {
        ...state,
        loginData: payload,
        isLoading: false,
      };
    }

    case REGISTER_SUCCESS: {
      ToastsStore.success(payload.message, 9000);
      const email = payload.user.email;
      window.location.href = `/account_verification?email=${email}`;
      return {
        ...state,
        registerData: payload.user,
        isLoading: false,
      };
    }

    case VERIFY_EMAIL_SUCCESS: {
      window.location.href = '/login';
      return {
        ...state,
        isLoading: false,
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      const email = payload.email;
      ToastsStore.success(payload.message, 9000);
      window.location.href =  `/forgotPasswordOTP?email=${email}`;
      return {
        ...state,
        isLoading: false,
      };
    }

    case FORGOT_PASSWORD_OTP_SUCCESS: {
      const email = payload.email;
      const otpNumber = payload.otpNumber;
      ToastsStore.success(payload.message, 9000);
      window.location.href = `/resetPasswordUpdate?email=${email}&otpNumber=${otpNumber}`;
      return {
        ...state,
        isLoading: false,
      };
    }

    case FORGOT_PASSWORD_UPDATE_SUCCESS: {
      ToastsStore.success(payload.message, 9000);
      window.location.href =  '/login';
      return {
        ...state,
        isLoading: false,
      };
    }

    case RESEND_VERIFY_EMAIL_SUCCESS: {
      ToastsStore.success(payload.message, 9000);
      return {
        ...state,
        isLoading: false,
      };
    }

    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case FORGOT_PASSWORD_OTP_FAIL:
    case FORGOT_PASSWORD_UPDATE_FAIL:
    case VERIFY_EMAIL_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}

export default authReducer;

/* eslint-disable prettier/prettier */
import React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export function HidePassword(props){
  return (
        <FiEyeOff onClick={e=> props.clickEyeSlash()}/>
    );
}

export default function ShowPassword(props){
    return (
        <FiEye onClick={e=> props.clickEye()}/>
    );
}
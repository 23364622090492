import React, { useEffect } from "react";
import {connect} from 'react-redux';
import './forgotPassword.scss';
import {Col, Row, Form} from "react-bootstrap";
import {Formik} from "formik";
import Button from "../../inputs/Button";
import {Link} from "react-router-dom";
import { showLoader, hideLoader, Yup } from '../../index';
import {FORGOT_PASSWORD} from "../../../action/authAction";
import {FormInput} from "../../inputs/FormInput";


const ForgotPassword = (props) => {
  const {auth, forgotPassword} = props;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .email("*Please enter a valid email address")
    .min(7, "*Email must have at least 7 characters")
    .max(50, "*Email must be less than 50 characters")
    .required("*Email is required"),
  });

  useEffect(() => {
    if(!auth.isLoading) {
      hideLoader();
    }
  }, [auth]);

  return (
    <div className="forgot-password-page page-area container-fluid">
      <div className="forgot-main-block">
        <Formik
          initialValues={{email: ''}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting}) => {
            showLoader();
            forgotPassword(values);
            setSubmitting(false);
          }}
        >
          {(formProps) => {
            const { values, errors, touched, handleChange, handleSubmit, isSubmitting} = formProps;
            return (
              <Form onSubmit={handleSubmit}>

                <Row className="forgot-main__inner">
                  <Col lg={8} sm={12} className="d-flex justify-content-center mt-3">
                    <h3>Forgot Password</h3>
                  </Col>

                  <Col lg={8} sm={12} className="px-4">
                    <div className="my-4">
                      <FormInput
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email ? errors.email : null}
                      />
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <Button type="submit" label="SUBMIT" disabled={isSubmitting} />
                  </Col>
                </Row>
              </Form>
          )}}
        </Formik>
      </div>


      <footer>
        <div className="page-footer">
          <p className="m-0 footer-content">
            Already a member?
            <Link to="/login"> Login here</Link>
          </p>
        </div>
      </footer>
    </div>
  )
};

function mapStateToProps({auth}) {
  return {auth}
}

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: (data) => dispatch({ type: FORGOT_PASSWORD, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React,{useState,useEffect} from 'react';
import {Col, Row,Form} from "react-bootstrap";
import Sidebar from './sidebar.jsx';
import './sidebar.scss';
import {  history } from '../index';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';

import "react-datepicker/dist/react-datepicker.css";

 
const Data = () => {
  
  const username = localStorage.getItem('username');
  const [data, setData] = useState([]);
  const [unitPrice, setUnitPrice] = useState(null);
  const [list_price, setValue] = useState(null);
  const [city, setcityValue] = useState(null);
  const [sold_price, setsold_priceValue] = useState(null);
  const [listing_status, setlisting_statusValue] = useState(null);
  const [address, setaddressValue] = useState(null);
  const [sold_date, setsold_dateValue] = useState(null);
  const [listing_discount, setlisting_discountValue] = useState(null);
  const [days_on_market, setdays_on_marketValue] = useState(null);
  const [list_date, setlist_dateValue] = useState(null);
  const [contract_date, setcontract_dateValue] = useState(null);
  const [showbutton, setshowbutton] = useState(null);
  const [ascOrder, ascOrderData] = useState('asc');
  const [sortType, sortTypeData] = useState('list_price');
  const [dataCount, dataCountText] = useState([]);
  const [pageActive, pageActiveData] = useState(1);
  const [recordType, recordTypeData] = useState('sale');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const [add, setadd] = useState([{ address: "38 Chapel Lane"},
    { address: "81 Day Lily Lane" },
    { address: "7 Deforest Road"}]);

  
  const apiUrl = 'http://54.160.96.88:3000/api/getCsvDatabyWeek';
  const [appState, setAppState] = useState([]);

   
  const fetchCsvCount = () => {
       fetch(`http://54.160.96.88:3000/api/getCsvDataWeekCount`, { 
            method: "POST",
            body: JSON.stringify({
                csvsheet_id:localStorage.getItem('scrapId'),
                pagination :pageActive*100,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => dataCountText(data.csvData))
  }

  const fetchCsvData = () => {
         if(ascOrder=='asc'){
             var sendtype= 'desc';
          }else {
             var sendtype= 'asc';
          }
   fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, { 
            method: "POST",
            body: JSON.stringify({
                typeData :sortType,
                orderBy:sendtype,
                csvsheet_id:localStorage.getItem('scrapId')
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => setData(data.csvData))

   
          

    }
 useEffect(() => {
  fetchCsvCount();
  fetchCsvData();
  if(!username){
      history.push('/login');
    }
    else{
      localStorage.setItem('setcolor',2);
      history.push('/WeeklyData');
    }
    
  },[]);

  const [sidebaropen,setsidebar]=useState(false);

  const opensidebar=()=>{
    setsidebar(true);
  };


  const onChangeEdit = (event) => {
    setValue(event.target.value); 
    setshowbutton(event.target.getAttribute("dataid"));
  };
  const onChangeEdit1 = ({id,val}) => { 
    setValue(val); 
    setshowbutton(id);
  };

  const onSoldPriceChangeEdit1 = ({id,val}) => { 
    setsold_priceValue(val); 
    setshowbutton(id);
  };
  

  const onCityChangeEdit = (event) => {  setcityValue(event.target.value); setshowbutton(event.target.getAttribute("dataid"));};
  const onSoldPriceChangeEdit = (event) => {  setsold_priceValue(event.target.value); setshowbutton(event.target.getAttribute("dataid"));};

  const onListingChangeEdit = (event) => {  setlisting_statusValue(event.target.value); setshowbutton(event.target.getAttribute("dataid"));};
  const onAddressChangeEdit = (event) => {  
  
  setaddressValue(event.target.value);
  setshowbutton(event.target.getAttribute("dataid")); };

  const onSoldChangeEdit = (date,dataid) => { setsold_dateValue(date);setshowbutton(dataid); };
  const onDiscountChangeEdit = (event) => {  setlisting_discountValue(event.target.value);setshowbutton(event.target.getAttribute("dataid")); };
  const onDaysonChangeEdit = (event) => {  setdays_on_marketValue(event.target.value); setshowbutton(event.target.getAttribute("dataid"));};
  const onlistdateChangeEdit = (date,dataid) => {  setlist_dateValue(date);setshowbutton(dataid); };
  const oncontractChangeEdit = (date,dataid) => { setcontract_dateValue(date);setshowbutton(dataid); };

   const onSave = ({id,cit,listprice,soldprice,listingstatus,addr,solddate,listingdiscount,daysonmarket,listdate,contractdate}) => {
        if(list_price!=null){ var listpriceval = list_price; }else { var listpriceval = listprice ; }
        if(city!=null){ var cityval = city; }else { var cityval = cit ; }
        if(sold_price!=null){ var sold_priceval = sold_price; }else { var sold_priceval = soldprice ; }
        if(listing_status!=null){ var listing_statusval = listing_status; }else { var listing_statusval = listingstatus ; }
        if(address!=null){ var addressval = address; }else { var addressval = addr ; }
        if(sold_date!=null){ 
          var sdate = new Date(sold_date);
          sdate.setDate(sdate.getDate() + 1)
         var sold_dateval = sdate; 
        }else { var sold_dateval = solddate ; }
        if(listing_discount!=null){ var listing_discountval = listing_discount; }else { var listing_discountval = listingdiscount ; }
        if(days_on_market!=null){ var days_on_marketval = days_on_market; }else { var days_on_marketval = daysonmarket ; }
        if(list_date!=null){ 
          var date = new Date(list_date);
          date.setDate(date.getDate() + 1)
          var list_dateval = date; 
        }else { var list_dateval = listdate ; }
        if(contract_date!=null){ 
         var cdate = new Date(contract_date);
          cdate.setDate(cdate.getDate() + 1)
        var contract_dateval = cdate;
         }else { var contract_dateval = contractdate ; }

        fetch(`http://54.160.96.88:3000/api/updateCsvData`, { 
            method: "POST",
            body: JSON.stringify({
                city: cityval,
                list_price :listpriceval,
                sold_price :sold_priceval,
                listing_status:listing_statusval,
                address: addressval,
                sold_date:sold_dateval,
                listing_discount: listing_discountval,
                days_on_market: days_on_marketval,
                list_date : list_dateval,
                contract_date :contract_dateval,
                _id:id
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(json => {
                alert(" Records Updated Successfully");
                fetchCsvData();
                setshowbutton('');
                setcityValue(null);
                setcontract_dateValue(null);
                setsold_priceValue(null);
                setlisting_statusValue(null);
                setaddressValue(null);
                setsold_dateValue(null);
                setlisting_discountValue(null);
                setdays_on_marketValue(null);
                setlist_dateValue(null);
            })
    }

    

    const onCancel = ({id}) => {
          setshowbutton('');
          setcityValue(null);
          setcontract_dateValue(null);
          setsold_priceValue(null);
          setlisting_statusValue(null);
          setaddressValue(null);
          setsold_dateValue(null);
          setlisting_discountValue(null);
          setdays_on_marketValue(null);
          setlist_dateValue(null);
    }
    
     const ondelete = ({id}) => {
       
        fetch(`http://54.160.96.88:3000/api/deleteCsvData`, {
            method: "POST",
            body: JSON.stringify({
                _id:id,
                pagination :pageActive*100,
                listing_type:recordType
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(json => {
                alert(" Records Deleted Successfully");
                fetchCsvData();
               
            })
    };

    
    const GetSearch= ({type}) => {
        recordTypeData(type);
        pageActiveData(1);

        fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, {
            method: "POST",
            body: JSON.stringify({
              listing_type:type,
              start_date:startDate,
              end_date:endDate
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => setData(data.csvData));


        fetch(`http://54.160.96.88:3000/api/getCsvDataCount`, { 
            method: "POST",
            body: JSON.stringify({
                listing_type:type,
                pagination :0,
                start_date:startDate,
                end_date:endDate
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => dataCountText(data.csvData))

  }

  const paginationData= ({skip}) => {
        
        if(skip==0){ 
          pageActiveData(1);
        }else { 
          pageActiveData(parseInt(skip)/100+1); 
        }
        fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, {
            method: "POST",
            body: JSON.stringify({
                pagination :skip,
                csvsheet_id:localStorage.getItem('scrapId'),
                start_date:startDate,
                end_date:endDate
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => setData(data.csvData))
  }

    const sortdata= ({type}) => {
          sortTypeData(type);
          if(ascOrder=='asc'){
              ascOrderData('desc');
          }else {
              ascOrderData('asc');
          }
          pageActiveData(1);
         fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, { 
            method: "POST",
            body: JSON.stringify({
                typeData :type,
                orderBy:ascOrder,
                pagination :0,
                csvsheet_id:localStorage.getItem('scrapId')
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => setData(data.csvData))
         
    }


    const searchdata = () => {
          pageActiveData(1);
          
          fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, { 
            method: "POST",
            body: JSON.stringify({
                listing_type:recordType,
                start_date:startDate,
                end_date:endDate
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => setData(data.csvData))

          fetch(`http://54.160.96.88:3000/api/getCsvDataCount`, { 
            method: "POST",
            body: JSON.stringify({
                listing_type:recordType,
                pagination :0,
                start_date:startDate,
                end_date:endDate
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => dataCountText(data.csvData))

    }

    const cleardata = () => {
      setStartDate(null);
      setendDate(null);
      fetchCsvCount();
      fetch(`http://54.160.96.88:3000/api/getCsvDatabyWeek`, { 
        method: "POST",
         body: JSON.stringify({
            listing_type:recordType,
            start_date:'',
            end_date:''
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(data => setData(data.csvData))
    }

    const ConfirmDelete = (id) => {
      var x = window.confirm("Are you sure  you want to delete the record?");
      if(x)
        ondelete({id: id})
      else
        return false;
    }

    const readdress = [
        { address: "38 Chapel Lane"},
        { address: "81 Day Lily Lane" },
        { address: "7 Deforest Road"},
    ];


  return (
     
    
     <div className="container-fluid mainpage">
     <div className="col-lg-12">
     <div className="row">
     <div className="col-md-2 background">
         <div className="sidebar">
           <Sidebar/>
        </div>
     </div>
    
     <div className="col-lg-10" style={{padding:'0px'}}>
    
    <div class="container-fluid">
      
        <div class="row">
          <div class="col-md-12">
             <h3 class="card-title" style={{color:'#000', marginTop:'20px'}}>Manage Data - { localStorage.getItem('scrapStatus') } - { localStorage.getItem('scrapDateRange') }</h3>  
            
        <div class="row" >
          <div class="col-md-12">
                  <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                   

                    <div class="row">
                       


                            <div class="col-sm-12 tableFixHead" style={{overflowY:"scroll",height:600}}>
                                <table id="example2" class="zui-table table-hover table-bordered">
                                  <thead>
                                   <tr role="row">
                                      <th>
                                        <a onClick={() => sortdata({type:'list_price'})} className="masterlabel">List<br/>Price</a>
                                        <div onClick={() => sortdata({type:'list_price'})}>
                                          { (sortType=='list_price' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'sold_price'})} className="masterlabel">Sold<br/>Price</a>
                                        <div onClick={() => sortdata({type:'sold_price'})}>
                                          { (sortType=='sold_price' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'listing_status'})} className="masterlabel">Listing<br/>Status</a>
                                        <div  onClick={() => sortdata({type:'listing_status'})}>
                                          { (sortType=='listing_status' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'address'})} className="masterlabel">Address</a>
                                        <div onClick={() => sortdata({type:'address'})}>
                                          { (sortType=='address' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'city'})} className="masterlabel">Town</a>
                                        <div onClick={() => sortdata({type:'city'})}>
                                          { (sortType=='city' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'list_date'})} className="masterlabel">List<br/>Date</a>
                                        <div onClick={() => sortdata({type:'list_date'})}>
                                          { (sortType=='list_date' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'contract_date'})} className="masterlabel">Contract<br/>Date</a>
                                        <div onClick={() => sortdata({type:'contract_date'})}>
                                          { (sortType=='contract_date' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'sold_date'})} className="masterlabel">Sold<br/>Date</a>
                                        <div onClick={() => sortdata({type:'sold_date'})}>
                                          { (sortType=='sold_date' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th style={{width:'100px'}}>
                                      <a onClick={() => sortdata({type:'listing_discount'})} className="masterlabel">Listing<br/>Discount</a>
                                        <div onClick={() => sortdata({type:'listing_discount'})} >
                                          { (sortType=='listing_discount' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th><a onClick={() => sortdata({type:'days_on_market'})} className="masterlabel">Days on<br/>Market</a>
                                        <div onClick={() => sortdata({type:'days_on_market'})}>
                                          { (sortType=='days_on_market' && ascOrder=='asc') ?
                                            <img src="/desc.png" style={{width:'8px',height:'8px',marginTop:'0px'}}/>
                                          :
                                            <img src="/asc.png" style={{width:'8px',height:'8px',marginTop:'-1px'}}/>
                                          }
                                        </div>
                                      </th>
                                      <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                   
                                     {

                                      data.map((item) => (
                                        <tr className={item.repeat==1 ? 'error' : ''}>
                                        
                                           <td>
                                            { (showbutton == item._id && list_price!=null) ?
                                              <input type="text" class="input" onChange={onChangeEdit} value={list_price} dataid={item._id} name="list_price" />
                                            :
                                              <NumberFormat value={item.list_price} class="input" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>}  
                                              onClick={() => onChangeEdit1({id: item._id,val:item.list_price})}
                                              />
                                            }
                                            </td>

                                            <td>
                                            { (showbutton == item._id && sold_price!=null) ?
                                              <input type="text" class="input" value={sold_price} dataid={item._id} name="sold_price" onChange={onSoldPriceChangeEdit} />
                                            :
                                             <NumberFormat value={item.sold_price} class="input" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>}  
                                              onClick={() => onSoldPriceChangeEdit1({id: item._id,val:item.sold_price})}
                                              />
                                            }
                                            </td>
                                            <td>
                                            { (showbutton == item._id && listing_status!=null) ?
                                              <input type="text" class="input" value={listing_status} dataid={item._id} name="listing_status" onChange={onListingChangeEdit} />
                                            :
                                              <input type="text" class="input" style={{border:'none',backgroundColor:'transparent'}} value={item.listing_status} dataid={item._id} name="listing_status" onChange={onListingChangeEdit} />
                                            }
                                            </td>
                                            <td>
                                            { (showbutton == item._id && address!=null) ?
                                              <textarea class="inputarea" value={address} dataid={item._id} name="address" onChange={onAddressChangeEdit} />
                                            :
                                              <textarea class="inputarea" style={{border:'none',backgroundColor:'transparent'}} value={item.address} dataid={item._id} name="address" onChange={onAddressChangeEdit} />
                                            }
                                            </td>
                                            <td>
                                            { (showbutton == item._id && city!=null) ?
                                              <input type="text" class="input"  value={city} dataid={item._id} name="city" onChange={onCityChangeEdit}/>
                                            :
                                              <input type="text" class="input" style={{border:'none',backgroundColor:'transparent'}} value={item.city} dataid={item._id} name="city" onChange={onCityChangeEdit}/>
                                            }
                                            </td>
                                            <td>
                                             { (showbutton == item._id && list_date!=null) ?
                                              <DatePicker className="input" selected={list_date} onChange={date => onlistdateChangeEdit(date,item._id)} dataid={item._id} dateFormat='yyyy-MM-dd' name="list_date"  />
                                            :
                                              <DatePicker value={item.list_date}  className="inputborder" dateFormat='yyyy-MM-dd' onChange={date => onlistdateChangeEdit(date,item._id)} dataid={item._id} name="list_date"  />
                                            }
                                            </td>
                                            <td>
                                            { (showbutton == item._id && contract_date!=null) ?
                                              <DatePicker className="input" selected={contract_date} onChange={date => oncontractChangeEdit(date,item._id)} dataid={item._id} dateFormat='yyyy-MM-dd' name="contract_date"  />
                                            :
                                              <DatePicker value={item.contract_date}  className="inputborder" dateFormat='yyyy-MM-dd' onChange={date => oncontractChangeEdit(date,item._id)} dataid={item._id} name="contract_date"  />
                                            }
                                            </td> 
                                            <td>
                                              { (showbutton == item._id && sold_date!=null) ?
                                              <DatePicker className="input" selected={sold_date} onChange={date => onSoldChangeEdit(date,item._id)} dataid={item._id} dateFormat='yyyy-MM-dd' name="sold_date"  />
                                              :
                                              <DatePicker value={item.sold_date}  className="inputborder" dateFormat='yyyy-MM-dd' onChange={date => onSoldChangeEdit(date,item._id)} dataid={item._id} name="sold_date"  />
                                              }
                                            </td>
                                            <td>
                                             { (showbutton == item._id && listing_discount!=null) ?
                                                <input type="text" class="input" value={listing_discount} dataid={item._id} name="listing_discount" onChange={onDiscountChangeEdit} />
                                              :
                                                <input type="text" class="input" style={{border:'none',backgroundColor:'transparent'}} value={item.listing_discount} dataid={item._id} name="listing_discount" onChange={onDiscountChangeEdit} />
                                              }
                                            </td>
                                            <td>
                                             { (showbutton == item._id && days_on_market!=null) ?
                                                <input type="text" class="input" value={days_on_market} dataid={item._id} name="days_on_market" onChange={onDaysonChangeEdit} />
                                              :
                                                <input type="text" class="input" style={{border:'none',backgroundColor:'transparent'}} value={item.days_on_market} dataid={item._id} name="days_on_market" onChange={onDaysonChangeEdit} />
                                              }
                                            </td>
                                            
                                            <td>
                                            {showbutton == item._id &&
                                                <a onClick={() => onCancel({id: item._id})} style={{float:'left'}}>
                                                  <img src="/cancel.png" style={{width:'20px',height:'20px'}} />
                                                </a>
                                            }

                                            {showbutton == item._id &&
                                                <a onClick={() => onSave({id: item._id, city:item.city,list_price:item.list_price,sold_price:item.sold_price,listing_status:item.listing_status,address:item.address,sold_date:item.sold_date,listing_discount:item.listing_discount,days_on_market:item.days_on_market,list_date:item.list_date,contract_date:item.contract_date})} style={{float:'left',marginLeft:10}}>
                                                  <img src="/edit.png" style={{width:'20px',height:'20px'}}/>
                                                </a>
                                            }
                                            
                                            <a onClick={() => ConfirmDelete(item._id)}>
                                              <img src="/trash-10-32.jpg" style={{width:'20px',height:'20px'}}/>
                                            </a>
                                        </td>
                                          
                                          
                                        </tr>
                                    ))
                                }

                                    
                                   

                                  
                                     
                                  </tbody>
                          
                        </table>
                
              </div>
             
            </div>
          </div>
            
             
           
         </div>
      </div>
    </div>
  </div></div>
   
     
</div>
</div>
      </div>
     </div>
     
     
    
     

  )
  }
  export default Data
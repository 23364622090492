import React,{useState} from 'react';
import {Col, Row,Form} from "react-bootstrap";
import Sidebar from './sidebar.jsx';
import './sidebar.scss';
import Button from "../inputs/Button";

 
const UploadData = () => {
 const [sidebaropen,setsidebar]=useState(false);
 const opensidebar=()=>{
  setsidebar(true);
}

  return (
     
    
     <div className="container-fluid mainpage">
     <div className="col-lg-12">
     <div className="row">
     <div className="col-md-2 background">
         <div className="sidebar">
           <Sidebar/>
        </div>
     </div>
    
     <div className="col-lg-10">
     <div> 
    <img src="https://cdn.icon-icons.com/icons2/916/PNG/512/Menu_icon_icon-icons.com_71858.png" alt="..." class="img-thumbnail" style={{width:'50px',height:'50px'}} onClick={()=>this.opensidebar()}/>
     </div>
     <div className="card"></div>
     <div className=" card-header text  elevation-3">
          

           
                                <Col lg={4} sm={4} className="px-4">
                                  <div>
                                   <lable> Upload CSV</lable>
                                   </div>
                                   </Col>
                                   <Col lg={4} sm={4} className="px-4">
                                   <div>
                                    <input
                                    type = "file"
                                      name="file"
                                      style = {{border: '1px solid #000', marginTop: '6px'}}
                                      />
                                  </div>
                                </Col>
                                <Col lg={4} sm={4} className="align-origin" style = {{ marginTop: '6px'}}>
                                  <Button type="submit" label="Upload"/>
                                 </Col>
     </div>
   
     
</div>
</div>
      </div>
     </div>
     
     
    
     

  )
  }
  export default UploadData
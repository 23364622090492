import {FETCH_PROFILE, FETCH_PROFILE_SUCCESS} from "../action/profile";

const initialState = {
  profileData: [],
};

const profileReducer = (state=initialState, {type, payload}) => {
  switch (type) {
    case FETCH_PROFILE: {
      return {
        ...state,
      };
    }

    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        profileData: payload,
      };
    }

    default:
      return state;
  }
}

export default profileReducer;

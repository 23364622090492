import React,{useState,useEffect} from 'react';
import {Col, Row,Form} from "react-bootstrap";
import { Chart } from "react-google-charts";
import {  history } from '../index';
import { BarChart } from 'react-charts-d3';

import { AreaChart } from 'react-charts-d3';
import { LineChart } from 'react-charts-d3';

import { ScatterChart } from 'react-charts-d3';
import { BubbleChart } from 'react-charts-d3';
import { PieChart } from 'react-charts-d3';



import Sidebar from './sidebar.jsx';
import './sidebar.scss';

import './dashboard.scss';
const DeshboardHome = () => {
  const username = localStorage.getItem('username');
 useEffect(() => {
    if(!username){
      history.push('/login');
    }
    else{
      localStorage.setItem('setcolor',0);
      history.push('/DashboardHome');
    }
    
  },[]);

  //console.log("username",localStorage.getItem('username'))
 const [sidebaropen,setsidebar]=useState(false);
 const opensidebar=()=>{
	setsidebar(true);
};
const data = [
  { key: 'Sale', values: [ { x: '04/04/2021', y: 345 }, { x: '04/11/2021', y: 432 }, { x: '04/18/2021', y: 892 },{ x: '04/28/2021', y: 564 },{ x: '04/31/2021', y: 900 }, ] },
];

const bubbledata = [
  { key: 'Group 1', values: [ { x: 'A', y: 23, r: 4 }, { x: 'B', y: 8, r: 19 } ] },
  { key: 'Group 2', values: [ { x: 'A', y: 15, r: 11 }, { x: 'B', y: 37, r: 21 } ] },
];

const piedata = [
  { label: 'Group 1', value: 23 },
  { label: 'Group 2', value: 15 },
];

 

  return (
     
    <div className="maindashboard">
     <div className="container-fluid mainpage" style={{height:750}}>
     <div className="col-lg-12">
     <div className="row">
     <div className="col-md-2 background">
         <div className="sidebar">
	         <Sidebar/>
	      </div>
     </div>
    
     <div className="col-lg-10" style={{padding:'30px'}}>
     
     
    
    <div style={{flexDirection:'row',width: '100%'}}>
      <div style={{width: '48%',margin:'1%',flex:1,float:'left',paddingLeft:30,paddingTop:20,paddingBottom:10, border:'1px solid #b3b3b3'}}> 
          
               <BarChart  data={data} />

      </div>

      <div style={{width: '48%',margin:'1%',flex:1,float:'left',paddingLeft:30,paddingTop:20,paddingBottom:10, border:'1px solid #b3b3b3'}}> 
          <AreaChart data={data} />
      </div>
      </div>

      

      <div style={{flexDirection:'row',width: '100%'}}>
      <div style={{width: '48%',margin:'1%',flex:1,float:'left',paddingLeft:30,paddingTop:20,paddingBottom:10, border:'1px solid #b3b3b3'}}> 
          
               <BubbleChart data={bubbledata} />

      </div>

      <div style={{width: '48%',margin:'1%',flex:1,float:'left',paddingLeft:30,paddingTop:20,marginBottom:'120px',paddingBottom:10, border:'1px solid #b3b3b3'}}> 
          <PieChart  data={piedata} />
      </div>
      </div>

      
</div>
</div>
      </div>
     </div>
     </div>
  )
  }
  export default DeshboardHome
import React from 'react';
import {Col, Row,Form,input} from "react-bootstrap";
import Button from "../inputs/Button";
import './login.scss';
import {FormInput} from "../inputs/FormInput";
import {Link} from "react-router-dom";
import { showLoader, hideLoader, history, Yup } from '../index';
import '../../App.css';
const DeshboardLogin = () => {

  return (
   <>
   <div className="bg-black ">
     
       <Form>
                            <Row className="inputbg-black login App-header">
                            <Col lg={12} sm={12} >
                             <div className ="logo-div" ></div>

                               </Col> 
                             <Col lg={8} sm={12} className="align-origin" style={{color:'#000', fontWeight:'normal'}}>
                                    <p>
                                        Admin Login
                                    </p>
                                  
                               </Col> 
                               
                                <Col lg={8} sm={8}>
                                  <div>
                                  
                                    <FormInput
                                      name="Email"
                                      placeholder="Email/Username"
                                      />
                                  </div>
                                </Col>
                                <Col lg={8} sm={8} >
                                  <div>
                                
                                    <FormInput
                                      name="Password"
                                      placeholder="Password"
                                      
                                    />
                                  </div>
                                </Col>
                                <Col lg={8} sm={8}  style={{marginBottom: '8px',marginTop:'10px'}}>
                                <input type="checkbox" name="checkbox" style={{ marginLeft: '6px'}} />
                                 <span className="remember-class">Remember me</span>
                                 </Col>
                                <Col lg={8} sm={8} className="align-origin" style={{display:'block',marginLeft: '38px'}}>
                                 <Link to="/DashboardHome" > <Button className="btn-adminlogin" type="submit" label="Log In"/></Link>
                                 </Col>
                               
                            </Row>
                           
       </Form>
       </div>
                            

    </>
  );
  
};




export default DeshboardLogin;

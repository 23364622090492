import React from 'react';
import {Col, Row,Form} from "react-bootstrap";
import Button from "../inputs/Button";
import {FormInput} from "../inputs/FormInput";
import '../../App.css';
import {Link} from "react-router-dom";
const DeshboardSignin = () => {

  return (
   <>
      
       <Form className="inputbg-black">
                            <Row className="inputbg-black">
                             <Col lg={8} sm={12} className="align-origin">
                                    <p>
                                        Dashboard Signin
                                    </p>
                                
                               </Col> 
                                <Col lg={8} sm={12} className="px-4">
                                  <div className="my-4">
                                    <FormInput
                                      name="UserName"
                                      placeholder="UserName"
                                      />
                                  </div>
                                </Col>
                                <Col lg={8} sm={12} className="px-4">
                                  <div className="my-4">
                                    <FormInput
                                      name="Email"
                                      placeholder="Email"
                                      />
                                  </div>
                                </Col>
                                <Col lg={8} sm={12} className="px-4">
                                  <div className="my-4">
                                    <FormInput
                                      name="Password"
                                      placeholder="Password"
                                      />
                                  </div>
                                </Col>
                                 <Col lg={8} sm={12} className="px-4">
                                  <div className="my-4">
                                    <FormInput
                                      name=" Confirm Password"
                                      placeholder="Confirm Password"
                                      />
                                  </div>
                                </Col>
                                <Col lg={6} sm={4}>
                                  <Link to="/DeshboardLogin">All ready have an Account ? login here</Link>
                                                          

                                </Col>
                                <Col lg={8} sm={4} className="align-origin">
                                  <Button type="submit" label="SUBMIT"/>
                                 </Col>
                            </Row>
                           
       </Form>
                            

    </>
  );
  
};




export default DeshboardSignin;

import React,{useState} from 'react';
import {Col, Row,Form} from "react-bootstrap";
import './sidebar.scss';
import {Link} from "react-router-dom";
import FontAwesome from 'react-fontawesome';
import { useParams } from "react-router-dom";

const Sidebar = () => {

const setcolor =  localStorage.getItem('setcolor');
const role = localStorage.getItem('userRole');


const [sidebaropen,setsidebar]=useState(false);
const opensidebar=()=>{
	setsidebar(true);
}
const closesidebar=()=>{
	setsidebar(false);
}

  return (        
    <nav className="navbar navbar-expand-lg navbar-light ">
  
		<div className="navBg pl-10 pr-0 ">
		 <div className="icon-divmain" style={{marginTop:'30px',marginLeft:'10px' }}>
		 	<img src="/graduapp-removebg-preview.png" style={{width:'100px',backgroundColor:'rgb(200,165,109)', borderRadius:10,height:'100px',marginTop:'10px'}}/>
		 </div>
		</div>
	     
	    <div style={{position: 'relative',top: '14rem',width:'100%'}} className="mobiletop">
			

			<div  className={setcolor==1 ? "nav-link activebk" : "nav-link"}>
				<Link to="/DataListing" className={ setcolor==1 ? "anav-linksel" : "anav-link"}> 
			  		<div className="icon-div2"></div>
			  		<span className="img-val">User Listing</span>
			 	</Link>
			</div>
			{role=='admin' &&
				<div  className={setcolor==2 ? "nav-link activebk" : "nav-link"}>
					<Link to="/Data" className={ setcolor==2 ? "anav-linksel" : "anav-link"}> 
			  		<div className="icon-div2"></div>
			  		<span className="img-val">School Listing</span>
				 </Link>
				</div>
			}
			<a href="/" className="nav-link">
				<div className="icon-div3"></div><span className="img-val anav-link" style={{marginRight:'-24px',top:'-20px'}}>Logout</span>
			</a>
	    </div>
		 
 
   </nav>

  )
  }
  export default Sidebar
import React, {useEffect} from "react";
import {connect} from "react-redux";
import "./resetPassword.scss";
import {Col, Row} from "react-bootstrap";
import Button from "../../inputs/Button";
import {FormInput} from "../../inputs/FormInput";
import {showLoader, hideLoader, Yup} from '../../index';
import {Formik} from "formik";


const ResetPasswordEmail = (props) => {
    const {auth, resetPassword} = props;

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("*Please enter a valid email address")
            .min(7, "*Email must have at least 7 characters")
            .max(50, "*Email must be less than 50 characters")
            .required("*Email is required"),
    });

    useEffect(() => {
        if (!auth.isLoading) {
            hideLoader();
        }
    }, [auth]);


    return (
        <div className="reset-password-email-page page-area container-fluid">

            <div className="page-main-block">
                <div className="reset-password__content">
                    <h3>Reset Password</h3>
                </div>
                <Formik
                    initialValues={{email: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log("values: ", values);
                        showLoader();
                        resetPassword(values);
                        setSubmitting(false);
                    }}
                >
                    {(formProps) => {
                        const {values, errors, isSubmitting, setFieldValue} = formProps;
                        return (
                            <Row className="page-main__inner">
                                <Col lg={8} sm={12} className="reset-password__desp">
                                    <p>
                                        Enter Your Email. We'll send you a link to reset your password.
                                    </p>
                                </Col>

                                <Col lg={8} sm={12}>
                                    <FormInput label="Email" name="email" type="email"
                                               error={errors.email}
                                               value={values.email}
                                               onChange={(val) => {
                                        setFieldValue("email", val.target.value);
                                    }}/>

                                </Col>
                                <Col lg={8} sm={12}>
                                    <Button type="submit" label="SUBMIT" disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        )
                    }}

                </Formik>
            </div>
        </div>
    )
        ;
};

function mapStateToProps({auth}) {
    return {auth};
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         resetPassword: (data) =>
//             dispatch({type: RESET_PASSWORD_EMAIL, payload: data}),
//     };
// };

export default connect()(ResetPasswordEmail);

/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Container, Button, Col } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import ShowPassword, { HidePassword } from './show_hide_password';

import styles from './styles.module.css'

export const LoginForm = ({ logo = '', heading = 'Login', initialValues, validationSchema, loginFormSubmit, redirectToSignup, redirectToForgotPassword }) => {
    const [passwordType, setPasswordType] = useState('password');
    const passwordEye = (<ShowPassword clickEye={e=> setPasswordType('password')}/>);
    const passwordEyeSlash = (<HidePassword clickEyeSlash={e=> setPasswordType('text')} />);

    return (
        <Container>
            <h1 className="text-center">{heading}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm})=> {
                    console.log("onSubmit hit");
                    loginFormSubmit(values, setSubmitting, resetForm);
                    // setSubmitting(true);
                }}
                
            >
                {({ errors, touched, values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field name="username" type="email"  className={`${errors.username && touched.username ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                        {errors.username && touched.username ? <div className={`${styles.red} mb-1`} >{errors.username}</div> : null}
                        <div className={styles.show_password}>
                            <Field name="password" type={passwordType} className={`${errors.password && touched.password ? styles.err : ''} ${styles.form_input_box} ${styles.w_100_p} mb-2`} />
                            <span>{passwordType === 'text'? passwordEye : passwordEyeSlash}</span>
                        </div>
                        {errors.password && touched.password ? (
                            <div className={`${styles.red} mb-1`} >{errors.password}</div>
                        ) : null}
                        <Col className="px-0">
                        <Field type="checkbox" name="rememberMe" />
                            <span className="pl-1">Remember me</span></Col>
                        <Button style={{backgroundColor: "#d53a3a"}} type="submit" disabled={isSubmitting} block>
                            Login
                        </Button>
                        <div className={styles.flex_container}>
                            <span>
                                <Button variant="link" className={`${styles.white_link}`} onClick={redirectToForgotPassword}>Forgot Password?</Button>
                            </span>
                            {/* <span  className={styles.flex_item_right}>
                                <span className={`${styles.v_align_middle}`}>Not a member yet? </span><Button variant="link" onClick={redirectToSignup}>Sign Up</Button>
                            </span> */}
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}
import React from "react";

const Home = (props) => {
    return (
        <div className="">
            <h3 className="text-center">Home Screen is under development.</h3>
        </div>
    )
};

export default Home;

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { LoginForm } from "../../core_components/react-asap-forms";
import {Link} from "react-router-dom";
import { showLoader, hideLoader, history, Yup } from '../index';
import {LOGIN} from "../../action/authAction";
import _ from "lodash";

const Login = (props) => {
  const {login, auth} = props;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .required("*Password is required"),
    username: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  });

  const initialValues =  {
    password: '',
    username: '',
    rememberMe: false
  }

  useEffect(() => {
    if(!auth.isLoading) {
      hideLoader();
    }
    if(_.get(auth.loginData,"user","")) {
      history.push('/DashboardHome');
    }
  }, [auth]);


  const formSubmit = (val, setSubmitting) => {
    const params = {email: val.username, password: val.password};
    
   
    showLoader();
    setSubmitting(false);
    fetch(process.env.REACT_APP_API_URL+`/auth/adminlogin`, {
       method: "POST",
         body: JSON.stringify({
             email :val.username,
             password:val.password
         }),
         headers: {
             "Content-type": "application/json; charset=UTF-8"
         }
       })
         .then(response => response.json())
        .then(data => {
          console.log('data',data);
          if(data){
           if(data.code==404){
              hideLoader();
              alert(data.message);
           }else{
             hideLoader();
             localStorage.setItem('username',val.username);
             localStorage.setItem('userRole',data.user.role);
             localStorage.setItem('schoolId',data.user.school);
             history.push('/DataListing');
           }
         }
       })
    /*if(val.username!="wallpon@admin.com" || val.password!="W@!!pOn@2021"){
        hideLoader();
        alert('Email and password incorrect')
    }else {
      hideLoader();
      history.push('/DashboardHome');
    }*/
    //login(params);
  };

  const redirectToSignup = () => {
    history.push('/signup');
  }

  const redirectToForgotPassword = () => {
    history.push('/forgotPassword');
  }

  var data = {
    heading: "Login",
    loginFormSubmit: formSubmit,
    validationSchema,
    initialValues,
    redirectToForgotPassword,
    redirectToSignup
  }

  return (
    <>
      <div className="newlogin-cls">
        <div style={{textAlign:'center'}}>
          <img src="/graduapp.jpeg" style={{width:'150px',height:'150px',marginTop:'50px',marginBottom:'50px'}}/>
        </div>
        <LoginForm {...data} />
        <div className ="end-div" > &nbsp;</div>
      </div>
    </>
  );
};

function mapStateToProps({auth}) {
  return {auth}
}

const mapDispatchToProps = dispatch => {
  return {
    login: (data) => dispatch({ type: LOGIN, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import './accountVerification.scss';
import { Link } from 'react-router-dom';
import {Col, Form, Row} from "react-bootstrap";
import OtpInput from "react-otp-input";
import {Formik} from "formik";
import Button from "../../inputs/Button";
import QueryString from "query-string";
import {RESEND_VERIFY_EMAIL, VERIFY_EMAIL} from "../../../action/authAction";
import {hideLoader, showLoader} from "../../../public/loader";
import Timer from "../../inputs/Timer";

const AccountVerification = (props) => {
  const {auth, verifyEmail, resendOtp} = props;
  const params = QueryString.parse(props.location.search);
  const [reload,setReload] =useState(false);
  const [isOtpExpired, setOtpExpired] =useState(false);
  const expireOTP = () => {
    setOtpExpired(!isOtpExpired);
  }

  useEffect(() => {
    if(!auth.isLoading) {
      hideLoader();
    }
  }, [auth]);

  return (
    <div className="account-verification-page page-area container-fluid">
      <div className="verification-main-block">
        <Formik
          initialValues={{email:params.email, otpNumber:''}}
          onSubmit={(values, {setSubmitting}) => {
            showLoader();
            verifyEmail(values);
            setSubmitting(false);
          }}
        >
          {(formProps) => {
            const { values, handleSubmit, isSubmitting, setFieldValue} = formProps;
            return (
              <Form onSubmit={handleSubmit}>
                <Row className="d-flex justify-content-center">
                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <h4 className="mb-4 font-weight-normal">Account Verification</h4>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <h4 className="mb-3">OTP sent to your email</h4>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner rqt-block">
                      <p className="rqt-content">Request confirmed. Please copy and paste the 4-digit OTP</p>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="otp-input-block">
                      <OtpInput
                        numInputs={4}
                        name="otpNumber"
                        value={values.otpNumber}
                        onChange={(val) => setFieldValue('otpNumber', val)}
                        containerStyle="otp-container"
                        inputStyle="otp-input"
                        focusStyle="otp-input-focus"
                        isInputNum="true"
                      />
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      <Button type="submit" label="SUBMIT" disabled={isSubmitting} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="v__inner my-3 ">
                      <div className="otp-expire-content">
                        <Timer reload={reload} expireOTP={expireOTP} />
                      </div>
                    </div>
                  </Col>

                  <Col lg={8} sm={12}>
                    <div className="v__inner">
                      {isOtpExpired ?
                        <Button label="Resend OTP" className="resend-otp-btn" onClick={() => {resendOtp({email:params.email}); setReload(!reload); expireOTP();}} />
                        : null
                      }
                    </div>
                  </Col>
                </Row>
              </Form>
            )}}
        </Formik>
      </div>

      <footer>
        <div className="page-footer">
          <p className="m-0 footer-content">
            Already a member?
            <Link to="/login"> Login here</Link>
          </p>
        </div>
      </footer>
    </div>
  );
};

function mapStateToProps({auth}) {
  return {auth}
}

const mapDispatchToProps = dispatch => {
  return {
    verifyEmail: (data) => dispatch({ type: VERIFY_EMAIL, payload: data }),
    resendOtp: (data) => dispatch({ type: RESEND_VERIFY_EMAIL, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerification);

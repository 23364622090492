import React,{useState,useEffect} from 'react';
import {Col, Row,Form,Button,Modal} from "react-bootstrap";
import Sidebar from './sidebar.jsx';
import './sidebar.scss';
import {Link} from "react-router-dom";
import {  history } from '../index';
import moment from "moment";    
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash"; 
import axios from "axios";
import {hideLoader, showLoader} from "../../public/loader";
import {FormInput} from "../inputs/FormInput";



const pageSize =10;

const ScrapData = () => {
  const username = localStorage.getItem('username');

   const [order, setorder] = useState("ASC");
  const [data, setData] = useState();
  const [datatext, setDatatext] = useState();
  const [deletetext, setDeletetext] = useState();
  const [pagination, setpagination] = useState();
  const [currentpage, setcurrentpage] = useState(1);


  const [showbutton, setshowbutton] = useState(null);
  const [settoggle, settogglebtn] = useState(false);
  const [pageCount, dataCountText] = useState([1]);
  const [pageActive, pageActiveData] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [ascOrder, ascOrderData] = useState('asc');
  const [sortType, sortTypeData] = useState('');
  const [showmodal, setshowmodal] = useState(false);
  const [showeditmodal, seteditmodal] = useState(false);
  const [imagetype, setimagetype] = useState('');
  const [imageurl, setimageurl] = useState('');
  const [owername, setowername] = useState('');
  const [address, setaddress] = useState('');
  const [domain, setdomain] = useState('');
  const [name, setname] = useState('');
  const [updateId, setupdateId] = useState('');

   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const fetchCsvCount = (pageCount) => {
       fetch(process.env.REACT_APP_API_URL+`users/getschoollisting`, { 
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
             body: JSON.stringify({
             page:pageCount
            }),
          })
            .then(response => response.json())
            .then(data => { setData(data.user); 
           
            })
            

    }

   const fetchschoolCount = () => {
       fetch(process.env.REACT_APP_API_URL+`users/getschoollistingCount`, { 
            method: "POST",
            
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          })
            .then(response => response.json())
            .then(data => {  
               const dataCount = data.user/10;
                   if (dataCount ===1) return null;
                   const pages = _.range(1, dataCount+1);
                   dataCountText(pages);
                 })
  }


 useEffect(() => {

    fetchschoolCount();
    fetchCsvCount(1);
  
    if(!username){
      history.push('/login');
    }
    else{
      localStorage.setItem('setcolor',2);
      history.push('/Data');
    }
    
  },[]);
 const [sidebaropen,setsidebar]=useState(false);
 const opensidebar=()=>{
  setsidebar(true);
}

const ConfirmDelete = (id) => {

      var x = window.confirm("Are you sure  you want to delete the record?");
      if(x){
       fetch(process.env.REACT_APP_API_URL+`users/deleteUserData`, { 

            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
             userId :id
            }),
          })
            .then(response => response.json())
            .then(data => { fetchCsvCount(currentpage); })

            
        //return false;      
      }
      else
        return false;
}
const Verifydata = (id) => {
      var x = window.confirm("Are you sure  you want to verify this school?");
      if(x){
       fetch(process.env.REACT_APP_API_URL+`users/verifyschool`, { 
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
             userid :id
            }),
          })
        .then(response => response.json())
        .then(data => {   fetchCsvCount(currentpage); })
       // return false;      
      }
      else
        return false;
}

const getschoolDetail = (_id) =>{

     fetch(process.env.REACT_APP_API_URL+`users/getschoolDetail`, { 
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
             body: JSON.stringify({
             school:_id
            }),
          })
            .then(response => response.json())
            .then(data => {
              setDatatext(data.user);
              setTimeout(function(){
                setshowmodal(true)
                hideLoader();
               },1000)
            
            })
}
const editschoolrecord =() =>{
   
   fetch(process.env.REACT_APP_API_URL+`users/updateschool`, { 
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
         userid :updateId,
         owername :owername,
         address :address,
         name :name,
         domain :domain
        }),
      })
    .then(response => response.json())
    .then(data => {  seteditmodal(false); fetchCsvCount(currentpage); })
}
const getschoolDetailedit = (_id,userid,name) =>{
     fetch(process.env.REACT_APP_API_URL+`users/getschoolDetail`, { 
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
             body: JSON.stringify({
             school:_id,
             
            }),
          })
            .then(response => response.json())
            .then(data => { 
                setupdateId(userid);
                setowername(data.user.name);
                setaddress(data.user.address);
                setname(name); 
                setdomain(data.user.domain);
                setTimeout(function(){
                    seteditmodal(true)
                    hideLoader();
                },1000)
            
            })
}

const openpopup = (schoolid) => {
    showLoader();
    getschoolDetail(schoolid);
}

const edit=(schoolid,userid,name)=>{
    showLoader();
    getschoolDetailedit(schoolid,userid,name);
}

const closepopup = () => {
    hideLoader();
  setshowmodal(false)
}
const closepopupedit = () => {
   
  seteditmodal(false)
}

 const sorting =(col) =>{
      if(order === "ASC"){
        const sorted = [...data].sort((a,b)=>
          a[col] >b[col] ? 1 : -1
          );
        setData(sorted);
        setorder("DSC");
      }
       if(order === "DSC"){
        const sorted = [...data].sort((a,b)=>
          a[col] <b[col] ? 1 : -1
          );
        setData(sorted);
        setorder("ASC");
      }
    }

   
    const paginationdata =(pageNo)=>{
        
       setcurrentpage(pageNo);
      const startIndex =(pageNo -1)* pageSize;
     
       fetchCsvCount(pageNo);
      setpagination(pageNo )
    }
    
  return (
     
    
     <div className="container-fluid mainpage">
     <div className="col-lg-12">
     <div className="row">
     <div className="col-md-2 background">
         <div className="sidebar">
           <Sidebar/>
        </div>
     </div>
    
     <div className="col-lg-10" style={{padding:'0px'}}>
     
    <div className="container-fluid">
      
        <div className="row">
          <div className="col-md-12">
          
             <h3 className="card-title" style={{color:'#000', marginTop:'20px'}}>School Listing</h3>  
             
        <div className="row" >
          <div className="col-md-12">
                  <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 tableFixHead" style={{background:'#ffffff'}}>
                        <table id="example2" className="zui-table table-hover table-bordered">
                      <thead>
                       <tr role="row" style={{background:'#DDEFEF',color:'#336B6B',font:'normal 13px Arial, sans-serif'}} className="scraptable">
                          <th>#</th>
                          <th style={{textAlign:'center'}} onClick={() => sorting("name") }>
                              <a className="masterlabel">Name</a>
                            </th>
                          <th style={{textAlign:'center'}} onClick={() => sorting("email") }>
                          <a  className="masterlabel">Email</a>
                              
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("isVerified") }>
                            <a className="masterlabel">Email Verified</a>
                              
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("createdAt") }>
                            <a className="masterlabel">Created Date</a>
                              
                            </th>
                            
                          <th style={{textAlign:'center',width: '130px'}}>Action</th>
                          
                      </tr>
                      </thead>
                      <tbody>
                       {
                       data && data.map((item,index) => ( 
                        <tr role="row" className="odd" key={index}>
                         
                          <td>{(currentpage-1)*10+index+1}</td>
                          
                          <td>
                                  {item.name}
                                  </td>
                                  <td>
                                 {item.email}
                                  </td>
                                  { item.isVerified ==false ?
                                   <td>False</td>
                                  :
                                   <td>True</td>
                                  }
                                  <td>
                                 {moment(item.createdAt).format('yyyy-MM-DD hh:mm A')}
                                  </td>
                                  
                          
                          <td>
                              { item.isVerified ==false ?
                               <a  onClick={() => ConfirmDelete(item.id) }  style={{marginLeft:'15px',float:'left'}}>
                                  {/*<img src="/trash-10-32.jpg" style={{width:'20px',height:'20px',marginTop:'0px',marginBottom:'10px'}}/>*/}
                                   <div style={{padding:'5px 10px',borderRadius:'5px',backgroundColor:'#ff0000',color:'#ffffff'}}>Deny</div>
                              </a> 
                              :
                              <a  onClick={() => ConfirmDelete(item.id) }  style={{marginLeft:'15px',float:'left'}}>
                                  {/*<img src="/trash-10-32.jpg" style={{width:'20px',height:'20px',marginTop:'0px',marginBottom:'10px'}}/>*/}
                                  <div style={{padding:'5px 15px',borderRadius:'5px',backgroundColor:'#ff0000',color:'#ffffff'}}>Delete</div>
                              </a> 
                             }

                             { item.isVerified ==false ?
                           
                                <a  onClick={() => Verifydata(item.id) }  style={{marginLeft:'15px',float:'left'}}>
                                    <div style={{padding:'5px 15px',borderRadius:'5px',backgroundColor:'#000000',color:'#ffffff'}}>Approve</div>
                                </a>
                              :
                                <a  onClick={(value) => edit(item.school,item.id,item.name)}  style={{marginLeft:'15px',float:'left'}}>
                                    <div style={{padding:'5px 15px',borderRadius:'5px',backgroundColor:'#000000',color:'#ffffff'}}>Edit</div>
                                </a>
                              }

                              
                               <a  onClick={(value) => openpopup(item.school)}  style={{marginLeft:'15px',float:'left'}}>
                                    <div style={{padding:'5px 15px',borderRadius:'5px',backgroundColor:'#000000',color:'#ffffff'}}>View</div>
                                </a>
                                
                             

                             
                          </td>


                        </tr>
                        ))
                    }
                        
                         
                      </tbody>
                  
                </table>
                <div style={{marginTop:'2%'}}>
                   <nav className="d-flex justify-content-center">
                  <ul className="pagination" >
                     {
                      pageCount.map((page) =>(
                         <li key={page} className={
                          page === currentpage? "page-item active":"page-item"
                         }>
                         <p className="page-link"
                         
                          onClick={() => paginationdata(page)}>
                         {page}</p>
                         </li>
                        ))
                     }
                   
                  </ul>
                  </nav>
                  </div>
                
                { showmodal ==true &&
                <div className="modal">  
                  <div className="modal-content" style={{ width: '40%'}}>
                    <div className="modal-body" style={{ padding:0,marginLeft:'10%'}}>
                        <p className="modaltext"><span style={{float:'left',width:'100px'}}>School Head: </span>{datatext.name}</p>
                        <p className="modaltext"><span style={{float:'left',width:'100px'}}>Address: </span>{datatext.address}</p>
                        <p className="modaltext"><span style={{float:'left',width:'100px'}}>Domain: </span>{datatext.domain}</p>
                        
                        { datatext.isVerified ==false ?
                        <p className="modaltext"><span style={{float:'left',width:'100px'}}>Verified: </span>False</p> 
                        :
                        <p className="modaltext"><span style={{float:'left',width:'100px'}}>Verified: </span>True</p> 
                      }
                       
                    </div>
                    <div className="modal-footer" style={{ justifyContent: "center",padding:0}}>
                     
                      <Button className="getwidthcss getblackbtn" onClick={() => closepopup() } style={{ marginTop: '1rem',padding: '5px'}}>Close</Button>
                    </div>

                    
                  </div>
                </div>
              }
              { showeditmodal ==true &&
                <div className="modal" style={{ zIndex: 100}}>  
                  <div className="modal-content" style={{ width: '40%'}}>
                    <div className="modal-body" style={{ padding:0,marginLeft:'10%'}}>
                    <FormInput label="School Head" name="owername" type="text"
                                    value={owername}
                                    onChange={(val) => {
                                        setowername(val.target.value);
                                    }} />
                                    <FormInput label="Address" name="Address" type="text"
                                               value={address}
                                               onChange={(val) => {
                                        setaddress(val.target.value);
                                    }}/>
                                    <FormInput label="Domain" name="Domain" type="text"
                                               
                                               value={domain}
                                               onChange={(val) => {
                                        setdomain(val.target.value);
                                    }}/>
                                    <FormInput label="name" name="name" type="text"
                                            value={name}
                                               onChange={(val) => {
                                        setname(val.target.value);
                                    }}/>
                     
                        
                        
                       
                    </div>
                    <div className="modal-footer" style={{ justifyContent: "center",padding:0,marginTop:"15px"}}>
                      
                      <Button className="getwidthcss getblackbtn" onClick={() => closepopupedit() } style={{ marginTop: '1rem',padding: '5px',marginRight:'10px'}}>Close</Button>
                      <Button className="getwidthcss getblackbtn" onClick={() => editschoolrecord() } style={{ marginTop: '1rem',padding: '5px'}}>Edit</Button>
                    </div>

                    
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
           
         </div>
      </div>
    </div>
  </div></div>
   
     
</div>
</div>
      </div>

     </div>
     
     
    
     

  )
  }
  export default ScrapData
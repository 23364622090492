import React, {useEffect, useReducer} from "react";

const Timer = (props) => {
    const difference = 100; // OTP will expire after 100 sec
    const [timeLeft, dispatch] = useReducer((state, {type, payload }) => {
        // console.log("interval run: ", state);
        if(type === 'inc' && state > 0) {
          return state - 1;
        }
        else if(type === 'set'){
            return difference;
        }
        else{
            clearInterval(payload);
            props.expireOTP();
            return state;
        }
    }, difference);

      useEffect(() => {
        dispatch({type: 'set'}); // to set the default value when click on rest otp from parent
        let intervalId = setInterval(() => {
          dispatch({type: 'inc', payload: intervalId});
        }, 1000);
        return () => clearInterval(intervalId);
      }, [props.reload]);

    return (
        <div>
            OTP expires in  {timeLeft} Seconds
        </div>
    );
};

export default Timer

import {post, put} from "../utils/api";

export const login = (data) => {
 // console.log("data",data)
  return post(`/auth/login`, data);
};

export const register = (data) => {
  return post(`/auth/register`, data);
};

export const verifyEmail = (data) => {
  return post(`/auth/verify-otp`, data);
};

export const resendVerifyEmail = (data) => {
  return post(`/auth/send-verify-otp`, data);
};

export const forgotPassword = (data) => {
  return post(`/auth/forgot-password`, data);
};

export const forgotPasswordOtp = (data) => {
  return post(`/auth/verify-reset-otp`, data);
};

export const resetPassword = (data) => {
  const params = {
    email: data.email,
    password: data.pass,
    otpNumber: data.otpNumber
  }
  return post(`/auth/reset-password`, params);
};

export const auth = {
  login,
  register,
  verifyEmail,
  resendVerifyEmail,
  forgotPassword,
  forgotPasswordOtp,
  resetPassword,
};

import {all, fork} from 'redux-saga/effects';
import authSaga from "./authSaga";
import profile from "./profile";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(profile),
  ]);
}

import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Button from "../../inputs/Button";
import { Link } from "react-router-dom";

const ResetPasswordSent = (props) =>{
    return (
        <div className="reset-password-sent-page page-area container-fluid">

          <div className="page-main-block">
            <div className="reset-password__content">
              <h3>Email Sent!</h3>
            </div>

            <Row className="page-main__inner">
              <Col lg={8} sm={12} className="reset-password__desp">
                <p>
                  Your request is already confirmed, please check your email to see the instructions to reset password
                </p>
              </Col>
              <Col lg={8} sm={12}>
                <Button type="submit" label="Done" />
              </Col>
            </Row>
          </div>
        </div>
      );
}

function mapStateToProps({ auth }) {
    return { auth };
  }

  // const mapDispatchToProps = (dispatch) => {
  //   return {
  //     forgotPassword: (data) =>
  //       // dispatch({ type: , payload: data }),
  //   };
  // };

  export default connect()(ResetPasswordSent);

import React from "react";
import './Button.scss';
import {Button as ReactButton} from "react-bootstrap";

const Button = (props) => {
  const {label, type, name, variant, onClick, disabled, className, preicon, size} = props;

  return(
    <ReactButton
      variant={variant}
      name={name}
      size={size}
      type={type || 'button'}
      className={`default-button ${className ? className : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {preicon && preicon}
      {label}
    </ReactButton>
  )
};

export default Button;

import React,{useState,useEffect} from 'react';
import {Col, Row,Form} from "react-bootstrap";
import Sidebar from './sidebar.jsx';
import './sidebar.scss';
import {  history } from '../index';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import moment from "moment"; 
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash"; 
import axios from "axios";

 const pageSize =10;
const DeshboardData = () => {
  
  const username = localStorage.getItem('username');
  const schoolId = localStorage.getItem('schoolId');
  const role = localStorage.getItem('userRole');

   const [order, setorder] = useState("ASC");
  const [data, setData] = useState();
  const [pagination, setpagination] = useState();
  const [currentpage, setcurrentpage] = useState(1);

  const [unitPrice, setUnitPrice] = useState(null);
  const [list_price, setValue] = useState(null);
  const [searchaddress, setsearchaddress] = useState(null);
  const [city, setcityValue] = useState(null);
  const [sold_price, setsold_priceValue] = useState(null);
  const [listing_status, setlisting_statusValue] = useState(null);
  const [address, setaddressValue] = useState(null);
  const [sold_date, setsold_dateValue] = useState(null);
  const [listing_discount, setlisting_discountValue] = useState(null);
  const [days_on_market, setdays_on_marketValue] = useState(null);
  const [list_date, setlist_dateValue] = useState(null);
  const [contract_date, setcontract_dateValue] = useState(null);
  const [showbutton, setshowbutton] = useState(null);
  const [ascOrder, ascOrderData] = useState('asc');
  const [sortType, sortTypeData] = useState('');
  const [pageCount, dataCountText] = useState([1]);
  const [dataTotalCount,dataTotalCountText]= useState(0);
  const [pageActive, pageActiveData] = useState(1);
  const [recordType, recordTypeData] = useState('sale');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

 

  
  const [appState, setAppState] = useState([]);

   
  

    const getuserlist = (pageNo) => {
      
      fetch(process.env.REACT_APP_API_URL+`users/getstudentlisting`, {
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
         schoolId: schoolId,
         role:role,
           page:pageNo
        }),
      })
      .then(response => response.json())
      .then(data => {
        if(data.code==404){
           setData([1])
        }else {
          setData(data.user)
        }
      })
    }

    const getstudentlistingCount = () => {
       fetch(process.env.REACT_APP_API_URL+`users/getstudentlistingCount`, { 
            method: "POST",
            
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
          role:role,
          schoolId:schoolId
        }),
          })
            .then(response => response.json())
            .then(data => { 
           
               const dataCount = data.user/10;
                   if (dataCount ===1) return null;
                   const pages = _.range(1, dataCount+1);
                   dataCountText(pages);
                 })
  }



 useEffect(() => {
  getstudentlistingCount();
   getuserlist(1);
  
 
  if(!username){
      history.push('/login');
    }
    else{
      localStorage.setItem('setcolor',1);
      history.push('/DataListing');
    }
    
  },[]);

  const [sidebaropen,setsidebar]=useState(false);

  const opensidebar=()=>{
    setsidebar(true);
  };

 
  

   
    const ConfirmDelete = (id) => {
      
      var x = window.confirm("Are you sure  you want to delete the record?");
      if(x){

        fetch(process.env.REACT_APP_API_URL+`users/deleteUserData`, { 

            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
             userId :id
            }),
          })
            .then(response => response.json())
            .then(data => { getuserlist(currentpage); })

        //return false;      
      }
      else
        return false;
    };

    const sorting =(col) =>{
      if(order === "ASC"){
        const sorted = [...data].sort((a,b)=>
          a[col] >b[col] ? 1 : -1
          );
        setData(sorted);
        setorder("DSC");
      }
       if(order === "DSC"){
        const sorted = [...data].sort((a,b)=>
          a[col] <b[col] ? 1 : -1
          );
        setData(sorted);
        setorder("ASC");
      }
    }

   const paginationdata =(pageNo)=>{
       
       setcurrentpage(pageNo);
      const startIndex =(pageNo -1)* pageSize;
     
       getuserlist(pageNo);
      setpagination(pageNo )
    }

    return (
     
      
     <div className="container-fluid mainpage">
     <div className="col-lg-12">
     <div className="row">
     <div className="col-md-2 background">
         <div className="sidebar">
           <Sidebar/>
        </div>
     </div>
    
     <div className="col-lg-10" style={{padding:'0px'}}>
    
      <div className="container-fluid">
        
          <div className="row">
            <div className="col-md-12">
               <h3 className="card-title" style={{color:'#000', marginTop:'20px'}}>User Listing</h3>  
              
          <div className="row" >
            <div className="col-md-12">
                    <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                     

                     

                      <div className="row">
                        

                    <div className="col-sm-12 tableFixHead" >
                          <table id="example2" className="zui-table table-hover table-bordered">
                        <thead>
                         <tr role="row">
                            <th>#</th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("name") }>
                              <a className="masterlabel" >Name</a>
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("email") }>
                            <a  className="masterlabel">Email</a>
                              
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("contactNumber") }>
                            <a className="masterlabel">Phone Number</a>
                              
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("dob") }>
                            <a  className="masterlabel">Dob</a>
                              
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("parentEmail") }>
                            <a className="masterlabel">Parent Email</a>
                             
                            </th>
                            <th style={{textAlign:'center'}} onClick={() => sorting("yearOfGraduation") }>
                            <a className="masterlabel">Graduation Year</a>
                             
                            </th>
                            
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                         
                           {
                            data && 
                            data.map((item,index) => (
                              <tr className={item.repeat==1 ? 'error' : ''} key={index}>
                                    <td>{(currentpage-1)*10+index+1}</td>
                                 <td>
                                  {item.name}
                                  </td>
                                  <td>
                                 {item.email}
                                  </td>
                                  <td>
                                  {item.contactNumber}
                                  </td>
                                   <td>
                                  {moment(item.dob).format('yyyy-MM-DD')}
                                  </td>
                                  <td>
                                  {item.parentEmail} 
                                  </td>
                                  <td>
                                   {item.yearOfGraduation}
                                  </td>
                                  

                                  <td>
                                  
                                  <a onClick={() => ConfirmDelete(item.id) }  style={{marginLeft:'15px'}}>
                                    {/*<img src="/trash-10-32.jpg" style={{width:'20px',height:'20px'}}/>*/}
                                    <div style={{padding:'5px 15px',margin:'0px 10px',width:70,borderRadius:'5px',backgroundColor:'#ff0000',color:'#ffffff'}}>Delete</div>
                                  </a>
                              </td>
                                
                                
                              </tr>
                          ))
                      }

                    
                           
                        </tbody>
                    
                  </table>
                  <div style={{marginTop:'2%'}}>
                  <nav className="d-flex justify-content-center">
                  <ul className="pagination" >
                     {
                      pageCount.map((page) =>(
                         <li key={page} className={
                          page === currentpage? "page-item active":"page-item"
                         }>
                         <p className="page-link"
                         
                          onClick={() => paginationdata(page)}>
                         {page}</p>
                         </li>
                        ))
                     }
                   
                  </ul>
                  </nav>
                </div>
                </div>
                
              </div>
            </div>
            
             
           
         </div>
      </div>
    </div>
  </div></div>
   
     
</div>
</div>
      </div>
     </div>
     
     
    
     

  )
  }
  export default DeshboardData
import {call, takeLatest} from "redux-saga/effects";
import {API} from "../api";
import {FETCH_PROFILE} from "../action/profile";
import {createPromiseAction, implementPromiseAction, rejectPromiseAction} from "@adobe/redux-saga-promise";

function* getProfile(action) {

  try {
    yield call(implementPromiseAction, action, function * () {
      return yield call(API.getProfile, action.payload);
    })
  } catch (e) {
    yield call(rejectPromiseAction, action, new Error(e.data.message()));
  }
}

function* profile() {
  yield takeLatest(createPromiseAction(FETCH_PROFILE), getProfile);
}

export default profile;

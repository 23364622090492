import React, {useEffect} from 'react';
import './FormInput.scss';
import {Form, Col} from 'react-bootstrap';
//import {useFormikContext} from "formik";
import _ from "lodash";

export const FormInput = (props) => {
  const {label, name, value, error, type, as=Col, placeholder, onChange, disabled} = props;

  let handleBlur, handleChange;
  const fieldTouched  =  null;
  useEffect(() => {
    if(fieldTouched){
      handleChange = fieldTouched.handleChange;
      handleBlur = fieldTouched.handleBlur;
    }
  }, [fieldTouched]);

  return (
    <Form.Row className="input-form-row">
      <Form.Group as={as} className="input-form-group">
        {label && (
          <Form.Label htmlFor={name} className="text-label">{label}</Form.Label>
        )}
        <Form.Control
          id={name}
          type={type || 'text'}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange ? onChange(e) : fieldTouched ? handleChange(e) : _.noop()}
          disabled={disabled}
          onBlur={(e) => fieldTouched ? handleBlur(e) : _.noop()}
          isInvalid={!!error}
          className="form-input-box"
        />

        <Form.Control.Feedback type="invalid" id={name}>
          {error}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  )
};

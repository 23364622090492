export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const RESEND_VERIFY_EMAIL = 'RESEND_VERIFY_EMAIL';
export const RESEND_VERIFY_EMAIL_SUCCESS = 'RESEND_VERIFY_EMAIL_SUCCESS';
export const RESEND_VERIFY_EMAIL_FAIL = 'RESEND_VERIFY_EMAIL_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP';
export const FORGOT_PASSWORD_OTP_SUCCESS = 'FORGOT_PASSWORD_OTP_SUCCESS';
export const FORGOT_PASSWORD_OTP_FAIL = 'FORGOT_PASSWORD_OTP_FAIL';

export const FORGOT_PASSWORD_UPDATE = 'FORGOT_PASSWORD_UPDATE';
export const FORGOT_PASSWORD_UPDATE_SUCCESS = 'FORGOT_PASSWORD_UPDATE_SUCCESS';
export const FORGOT_PASSWORD_UPDATE_FAIL = 'FORGOT_PASSWORD_UPDATE_FAIL';



import { call, put, takeLatest } from 'redux-saga/effects';
import {API} from "../api";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_OTP, FORGOT_PASSWORD_OTP_FAIL, FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_UPDATE, FORGOT_PASSWORD_UPDATE_FAIL, FORGOT_PASSWORD_UPDATE_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS, REGISTER,
  REGISTER_FAIL,
  REGISTER_SUCCESS, RESEND_VERIFY_EMAIL, RESEND_VERIFY_EMAIL_FAIL,
  RESEND_VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL,
  VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_SUCCESS,
} from "../action/authAction";

function* login(action) {
  try {
    const response = yield call(API.login, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: LOGIN_FAIL, payload: { error: e } });
  }
}

function* register(action) {
  try {
    const response = yield call(API.register, action.payload);
    yield put({ type: REGISTER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: REGISTER_FAIL, payload: { error: e } });
  }
}

function* verifyEmail(action) {
  try {
    const response = yield call(API.verifyEmail, action.payload);
    yield put({ type: VERIFY_EMAIL_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: VERIFY_EMAIL_FAIL, payload: { error: e } });
  }
}

function* resendVerifyEmail(action) {
  try {
    const response = yield call(API.resendVerifyEmail, action.payload);
    yield put({ type: RESEND_VERIFY_EMAIL_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: RESEND_VERIFY_EMAIL_FAIL, payload: { error: e } });
  }
}

function* forgotPassword(action) {
  try {
    const response = yield call(API.forgotPassword, action.payload);
    response.data.email = action.payload.email;
    yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_FAIL, payload: { error: e } });
  }
}

function* forgotPasswordOtp(action) {
  try {
    const response = yield call(API.forgotPasswordOtp, action.payload);
    response.data.email = action.payload.email;
    response.data.otpNumber = action.payload.otpNumber;
    yield put({ type: FORGOT_PASSWORD_OTP_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_OTP_FAIL, payload: { error: e } });
  }
}

function* resetPassword(action) {
  try {
    const response = yield call(API.resetPassword, action.payload);
    yield put({ type: FORGOT_PASSWORD_UPDATE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_UPDATE_FAIL, payload: { error: e } });
  }
}

function* auth() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(REGISTER, register);
  yield takeLatest(VERIFY_EMAIL, verifyEmail);
  yield takeLatest(RESEND_VERIFY_EMAIL, resendVerifyEmail);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(FORGOT_PASSWORD_OTP, forgotPasswordOtp);
  yield takeLatest(FORGOT_PASSWORD_UPDATE, resetPassword);
}

export default auth;
